import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/Main'),
    children: [
      // 主页
      {
        path: '/',
        component: () => import('../views/Home')
      },

      // 头部路由
      {
        path: '/header/notice',
        component: () => import('../views/Header/Notice')
      },
      {
        path: '/header/newsletter',
        component: () => import('../views/Header/Newsletter')
      },
      {
        path: '/header/worktask',
        component: () => import('../views/Header/Worktask')
      },
      {
        path: '/header/expire',
        component: () => import('../views/Header/Expire')
      },

      // 企业信息
      // 企业管理
      {
        path: '/filemanage/baseinfo',
        component: () => import('../views/FileManage/CompanyManage/BaseInfo')
      },
      {
        path: '/filemanage/organfig',
        component: () => import('../views/FileManage/CompanyManage/OrganFig')
      },
      {
        path: '/filemanage/companymanage/qualicard',
        component: () => import('../views/FileManage/CompanyManage/QualiCard')
      },
      {
        path: '/filemanage/departdetail',
        component: () => import('../views/FileManage/CompanyManage/DepartDetail')
      },
      {
        path: '/filemanage/safeorgan',
        component: () => import('../views/FileManage/CompanyManage/SafeOrgan')
      },
      // 车辆管理
      {
        path: '/filemanage/routemanage',
        component: () => import('../views/FileManage/CartManage/RouteManage')
      },
      {
        path: `/filemanage/cartfile`,
        component: () => import('../views/FileManage/CartManage/CartFile')
      },
      {
        path: '/filemanage/assess/:id',
        component: () => import('../views/FileManage/CartManage/assess')
      },
      {
        path: '/filemanage/historylib',
        component: () => import('../views/FileManage/CartManage/HistoryLib')
      },
      // 设备设施
      {
        path: '/filemanage/stopcart',
        component: () => import('../views/FileManage/Facilities/StopCart')
      },
      {
        path: '/filemanage/facilitiemanage',
        component: () => import('../views/FileManage/Facilities/FacilitieManage')
      },
      {
        path: '/filemanage/thingnetmanage',
        component: () => import('../views/FileManage/Facilities/ThingNetManage')
      },
      {
        path: '/filemanage/mainten/:id',
        component: () => import('../views/FileManage/Facilities/mainten')
      },
      {
        path: '/filemanage/check/:id',
        component: () => import('../views/FileManage/Facilities/check')
      },
      // 人员管理
      {
        path: '/filemanage/joberfile',
        component: () => import('../views/FileManage/PeopleManage/JoberFile')
      },
      {
        path: '/filemanage/joberfile/dutylist/:empId',
        component: () => import('../views/FileManage/PeopleManage/joberfile/Dutylist')
      },
      {
        path: '/filemanage/violarecord',
        component: () => import('../views/FileManage/PeopleManage/ViolaRecord')
      },
      {
        path: '/filemanage/peopleManage/qualicard',
        component: () => import('../views/FileManage/PeopleManage/QualiCard')
      },
      // 相关方管理
      {
        path: '/filemanage/relafile',
        component: () => import('../views/FileManage/RelaParManage/RelaFile')
      },
      {
        path: '/filemanage/relapeople',
        component: () => import('../views/FileManage/RelaParManage/RelaPeople')
      },
      {
        path: '/filemanage/constrmanage',
        component: () => import('../views/FileManage/RelaParManage/ConstrManage')
      },
      // 智能办公
      {
        path: '/smartoffice/meetingnotice',
        component: () => import('../views/Smartoffice/Meetingnotice')
      },
      {
        path: '/smartoffice/trainnotice',
        component: () => import('../views/Smartoffice/Trainnotice')
      },
      {
        path: '/smartoffice/fileissued',
        component: () => import('../views/Smartoffice/FileIssued')
      },
      {
        path: '/smartoffice/cardaudit',
        component: () => import('../views/Smartoffice/Cardaudit')
      },
      //安全生产
      {
        path: '/safeproduct/safemeeting',
        component: () => import('../views/Safeproduct/Safemeeting')
      },
      {
        path: '/safeproduct/drivinglog',
        component: () => import('../views/Safeproduct/Drivinglog')
      },
      {
        path: '/safeproduct/checklist',
        component: () => import('../views/Safeproduct/Security/Checklist')
      },
      {
        path: '/safeproduct/checkplan',
        component: () => import('../views/Safeproduct/Security/Checkplan')
      },
      {
        path: '/safeproduct/checkcont/:id',
        component: () => import('../views/Safeproduct/Security/checkcont')
      },
      {
        path: '/safeproduct/cultrueactive',
        component: () => import('../views/Safeproduct/CultrueActive')
      },
      {
        path: '/safeproduct/feesdistill',
        component: () => import('../views/Safeproduct/FeesManage/Feesdistill')
      },
      {
        path: '/safeproduct/feesbudget',
        component: () => import('../views/Safeproduct/FeesManage/Feesbudget')
      },
      //动态监管
      {
        path: '/dynamicmonitor/cartlocate',
        component: () => import('../views/DynamicMonitor/Cartlocate')
      },
      {
        path: '/dynamicmonitor/cartalarm',
        component: () => import('../views/DynamicMonitor/Cartalarm')
      },
      {
        path: '/dynamicmonitor/cartpeccant',
        component: () => import('../views/DynamicMonitor/Cartpeccant')
      },
      //双控体系
      {
        path: '/dubleconsystem/signlibrary',
        component: () => import('../views/DubleconSystem/Safesign/Signlibrary')
      },
      {
        path: '/dubleconsystem/signmanage',
        component: () => import('../views/DubleconSystem/Safesign/Signmanage')
      },
      {
        path: '/dubleconsystem/risklibrary',
        component: () => import('../views/DubleconSystem/Riskmanage/Risklibrary')
      },
      {
        path: '/dubleconsystem/riskreport',
        component: () => import('../views/DubleconSystem/Riskmanage/Riskreport')
      },
      {
        path: '/dubleconsystem/riskidentifi',
        component: () => import('../views/DubleconSystem/Riskmanage/Riskidentifi')
      },
      {
        path: '/dubleconsystem/riskgrading',
        component: () => import('../views/DubleconSystem/Riskmanage/Riskgrading')
      },
      {
        path: '/dubleconsystem/riskunit',
        component: () => import('../views/DubleconSystem/Riskmanage/Riskunit')
      },
      {
        path: '/dubleconsystem/Riskinventory',
        component: () => import('../views/DubleconSystem/Riskmanage/Riskinventory')
      },
      // 隐患治理
      {
        path: '/dubleconsystem/hiddenlibrary',
        component: () => import('../views/DubleconSystem/Hiddenmanage/Hiddenlibrary')
      },
      {
        path: '/dubleconsystem/hiddenreport',
        component: () => import('../views/DubleconSystem/Hiddenmanage/HiddenReport')
      },
      {
        path: '/dubleconsystem/hiddenaudit',
        component: () => import('../views/DubleconSystem/Hiddenmanage/HiddenAudit')
      },
      {
        path: '/dubleconsystem/HiddenAmend',
        component: () => import('../views/DubleconSystem/Hiddenmanage/HiddenAmend')
      },
      {
        path: '/dubleconsystem/hiddenrectifi',
        component: () => import('../views/DubleconSystem/Hiddenmanage/HiddenRectifi')
      },
      {
        path: '/dubleconsystem/hiddenreview',
        component: () => import('../views/DubleconSystem/Hiddenmanage/HiddenReview')
      },
      {
        path: '/dubleconsystem/hiddenquery',
        component: () => import('../views/DubleconSystem/Hiddenmanage/Hiddenquery')
      },
      {
        path: '/dubleconsystem/HiddenFile',
        component: () => import('../views/DubleconSystem/Hiddenmanage/HiddenFile')
      },
      {
        path: '/dubleconsystem/HiddenDestroy',
        component: () => import('../views/DubleconSystem/Hiddenmanage/HiddenDestroy')
      },
      // 事故事件
      {
        path: '/accidents/accidentreport',
        component: () => import('../views/Accidents/AccidentReport')
      },
      {
        path: '/accidents/emergencyissued',
        component: () => import('../views/Accidents/EmergencyIssued')
      },
      {
        path: '/accidents/accidentsurvey',
        component: () => import('../views/Accidents/AccidentSurvey')
      },
      {
        path: '/accidents/notletgo',
        component: () => import('../views/Accidents/Notletgo')
      },
      {
        path: '/accidents/accidentstatistic',
        component: () => import('../views/Accidents/AccidentStatistic')
      },
      // 应急管理
      {
        path: '/urgentmanage/preplan',
        component: () => import('../views/Urgentmanage/Preplan')
      },
      {
        path: '/urgentmanage/supplies',
        component: () => import('../views/Urgentmanage/Supplies')
      },
      {
        path: '/urgentmanage/supplies/outbound',
        component: () => import('../views/Urgentmanage/supplies/Outbound')
      },
      {
        path: '/urgentmanage/supplies/inbound',
        component: () => import('../views/Urgentmanage/supplies/Inbound')
      },
      {
        path: '/urgentmanage/supplies/allocate',
        component: () => import('../views/Urgentmanage/supplies/Allocate')
      },
      {
        path: '/urgentmanage/team',
        component: () => import('../views/Urgentmanage/Team')
      },
      {
        path: '/urgentmanage/drill',
        component: () => import('../views/Urgentmanage/Drill')
      },
      // 职业健康
      {
        path: '/jobhealth/injureperson',
        component: () => import('../views/Jobhealth/InjurePerson')
      },
      {
        path: '/jobhealth/checkrecord',
        component: () => import('../views/Jobhealth/CheckRecord')
      },
      //培训考试
      {
        path: '/trainingexam/courseware',
        component: () => import('../views/TrainingExam/Courseware')
      },
      {
        path: '/trainingexam/training',
        component: () => import('../views/TrainingExam/Training')
      },
      {
        path: '/trainingexam/database',
        component: () => import('../views/TrainingExam/Database')
      },
      {
        path: '/trainingexam/questions',
        component: () => import('../views/TrainingExam/Questions')
      },
      {
        path: '/trainingexam/paper',
        component: () => import('../views/TrainingExam/Paper')
      },
      {
        path: '/trainingexam/exam',
        component: () => import('../views/TrainingExam/Exam')
      },
      //培训考试
      {
        path: '/learngarden/laws',
        component: () => import('../views/LearnGarden/Laws')
      },
      {
        path: '/learngarden/technology',
        component: () => import('../views/LearnGarden/Technology')
      },
      {
        path: '/learngarden/accidentcases',
        component: () => import('../views/LearnGarden/Accidentcases')
      },
      {
        path: '/learngarden/safeknow',
        component: () => import('../views/LearnGarden/Safeknow')
      },
      //安标体系
      {
        path: '/anmarksystem/filemanage',
        component: () => import('../views/AnmarkSystem/Filemanage')
      },
      {
        path: '/anmarksystem/signmanage',
        component: () => import('../views/AnmarkSystem/Signmanage')
      },
      {
        path: '/anmarksystem/ratreport',
        component: () => import('../views/AnmarkSystem/Ratreport')
      },
      //统计报表
      {
        path: '/statsreport/cartreport',
        component: () => import('../views/StatsReport/Cartreport')
      },
      {
        path: '/statsreport/jobholdersport',
        component: () => import('../views/StatsReport/Jobholdersport')
      },
      {
        path: '/statsreport/riskclassifiport',
        component: () => import('../views/StatsReport/Riskclassifiport')
      },
      {
        path: '/statsreport/hiddenport',
        component: () => import('../views/StatsReport/Hiddenport')
      },
      {
        path: '/statsreport/accidentport',
        component: () => import('../views/StatsReport/Accidentport')
      },
      {
        path: '/statsreport/safemeetingport',
        component: () => import('../views/StatsReport/Safemeetingport')
      },
      //绩效考核
      {
        path: '/perforassess/pointlist',
        component: () => import('../views/PerforAssess/Pointlist')
      },
      //参数设置
      {
        path: '/parameset/points',
        component: () => import('../views/ParameSet/Points')
      },
      {
        path: '/parameset/timeset',
        component: () => import('../views/ParameSet/Timeset')
      },
      {
        path: '/parameset/notice',
        component: () => import('../views/ParameSet/Notice')
      },
      {
        path: '/parameset/venuemanage',
        component: () => import('../views/ParameSet/Venuemanage')
      },
      {
        path: '/parameset/function',
        component: () => import('../views/ParameSet/Function')
      },
      {
        path: '/parameset/data',
        component: () => import('../views/ParameSet/Data')
      },
      {
        path: '/parameset/data/list',
        component: () => import('../views/ParameSet/data/List')
      },
      {
        path: '/parameset/department',
        component: () => import('../views/ParameSet/Department')
      },
      {
        path: '/parameset/organization',
        component: () => import('../views/ParameSet/Organization')
      },
      {
        path: '/parameset/warehouse',
        component: () => import('../views/ParameSet/Warehouse')
      },
      {
        path: '/parameset/appVersion',
        component: () => import('../views/ParameSet/appVersion')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound404.vue'),
  }

]

const router = new VueRouter({
  routes
})
//解决重复路由
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
// 全局前置守卫
router.beforeEach((to, from, next) => {
  let islogin = false
  let tok = sessionStorage.getItem('token')
  islogin = tok ? true : false
  //判断用户登录状态跳转页面
  if (to.path !== '/login' && !islogin) {
    next({ path: '/login' })
  }
  else {
    next()
  }
})

export default router
