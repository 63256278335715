export default{
    namespaced: true,
    state(){
        return{
            status:false,//企业名字logo根据状态响应变化
            dengsta:false,//
        }
    },
    mutations: {
        mutaState(state){
            state.status=!state.status
        },
        mudeState(state){
            state.dengsta=!state.dengsta
        }
    }
}