export default{
    namespaced: true,
    state(){
        return {
            activeName:'first',       //首页tab是否激活
        }
    },
    mutations: {
        // 侧边菜单点击得到的信息
        gohistory(state,id){
            state.tabid=id
        },

    }
}