import Vue from 'vue'
import Vuex from 'vuex'
import asidemenu from './asidemenu'
import cartmanage from './cartmanage'
import header from './header'
Vue.use(Vuex)

export default new Vuex.Store({
  // state: {
  // },
  // getters: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    asidemenu,
    cartmanage,
    header
  }
})
