export default{
    namespaced: true,
    state(){
        return {
            syidx:0,       //首页tab是否激活
            idx:null,     //点击侧边菜单的唯一标识例：1-1
            tabinfo:[], //自定义增加标签数组信息
            tabindex:null,  //tab激活下标
            defaultindex:null, //menu默认激活下标
            status:false,
            lawId:0,
        }
    },
    mutations: {
        mutaState(state,lawId){//法律法规更多跳转
            state.status=!state.status
            state.lawId=lawId
            // state.defaultindex=null
            // state.idx=null
        },
        // 侧边菜单点击得到的信息
        getTabinfo(state,[info,idx]){
            console.log(info,idx);
            state.syidx=0 //首页tab未激活
            
            state.tabinfo=info
            state.idx=idx
            // console.log(idx);
                //点击侧边菜单，自定义增加tab的active跟随
                for (let i in state.tabinfo) {
                    if (state.tabinfo[i].i == state.idx) {
                        state.tabindex = i
                    }
                }
                if(state.tabinfo.length==12){//tab标签等于12 个时删除第一个tab
                    state.tabinfo.splice(0,1)
                    state.tabindex=state.tabinfo.length-1
                }
            // sessionStorage.setItem('tabInfo',JSON.stringify(info))
            // state.tabinfo=JSON.parse(sessionStorage.tabInfo)||{}
        },
        getheadinfo(state,[headtab,itemi]){
            console.log(headtab,itemi);
            state.syidx=0 //首页tab未激活
            state.defaultindex=null
            
            state.tabinfo=headtab
            state.idx=itemi
            //点击菜单，自定义增加tab的active跟随
            for (let i in state.tabinfo) {
                if (state.tabinfo[i].i == state.idx) {
                    state.tabindex = i
                }
            }
        },
        // 点击标签更改active
        updTabindex(state,[index,i]){
            state.tabindex=index
            state.defaultindex=i //点击tab，侧边菜单的active跟随
            state.syidx=0 //熄灭首页tab
        },
        upsyidx(state){
            state.syidx=1 //激活首页tab
            state.tabindex=null
            state.defaultindex=null
        },
        removeTab(state,index){
            state.tabinfo.splice(index,1) //删除所选中的tab
            if(state.tabindex>index){
                state.tabindex-=1
            }else if(state.tabindex==index){
                state.tabindex=state.tabinfo.length-1
                if(state.tabinfo.length){
                    state.defaultindex=state.tabinfo[state.tabinfo.length-1].i //删除tab，侧边菜单的active跟随
                    window.Vue.$router.push(`${state.tabinfo[state.tabinfo.length-1].pt}`)
                }else{
                    state.defaultindex=''
                    window.Vue.$router.push('/')
                    state.syidx=1
                }
            }
        }
    }
}