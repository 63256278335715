<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default{
  // created () {
  //   if(this.$router.path !== '/'){
  //     this.$router.replace('/')
  //   }
  // },
}
</script>

<style lang="less">
.el-button--primary{
  background-color:#3E7FE7 !important;
  border-color:transparent !important;
}
</style>
