export function getDate(date) {
    var year = new Date(date).getFullYear();
    var month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
    var day = (new Date(date).getDate()).toString().padStart(2, "0");
    return `${year}-${month}-${day}`
}
export function getNowWeek() {
    var nowDate = new Date();
    var initTime = new Date();
    initTime.setMonth(0); // 本年初始月份
    initTime.setDate(1); // 本年初始时间
    var differenceVal = nowDate - initTime; // 今天的时间减去本年开始时间，获得相差的时间
    var todayYear = Math.ceil(differenceVal / (24 * 60 * 60 * 1000)); // 获取今天是今年第几天
    var index = Math.ceil(todayYear / 7) + 1;
    return index;
}
// 获取今天的上个月或下个月日期
export function getXmonthToday(type) {
    // type 0 是当天 -1 是上个月   1是下个月
    var now = new Date(); // 可以传值调式 now = new Date(2019,2,30); 今天是3月30号
    var year = now.getFullYear(); //getYear()+1900=getFullYear()
    var month = now.getMonth() + 1; //0-11表示1-12月
    var day = now.getDate(); // 当天日期
    if (parseInt(month) < 10) {
        month = "0" + month;
    }
    if (parseInt(day) < 10) {
        day = "0" + day;
    }
    now = year + "-" + month + "-" + day; // 如果取当月日期可直接 return 返回

    var preMonth = parseInt(month) - 1;
    preMonth = preMonth < 10 ? "0" + preMonth : preMonth; // 获取上个月的值
    var nextMonth = parseInt(month) + 1;
    nextMonth = nextMonth < 10 ? "0" + nextMonth : nextMonth; // 获取下个月个月的值

    if (parseInt(month) == 1 && type == -1) {
        //如果是1月份，要上个月 ，则取上一年的12月份
        return parseInt(year) - 1 + "-12-" + day;
    } else if (parseInt(month) == 12 && type == 1) {
        // 如果是12月，要下个月，取下一年的1月
        return parseInt(year) + 1 + "-01-" + day;
    }

    var preSize = new Date(year, parseInt(month) - 1, 0).getDate(); //上月总天数
    var nextSize = new Date(year, parseInt(month) + 1, 0).getDate(); //下月总天数
    console.log(preSize, nextSize);
    if (preSize < parseInt(day) && type == -1) {
        // 取上个月，如果上个月总天数小于本月今天，取上个月最后一天
        return year + "-" + preMonth + "-" + preSize;
    } else if (nextSize < parseInt(day) && type == 1) {
        // 如果下个月总天数小于本月今天，取下个月最后一天
        return year + "-" + nextMonth + "-" + nextSize;
    }

    if (type == -1) {
        return year + "-" + preMonth + "-" + day;
    } else if (type == 1) {
        return year + "-" + nextMonth + "-" + day;
    } else if (type == 0) {
        return now;
    }
}

/**
 * 表格时间格式化
 */
export function formatDate(cellValue) {
    if (cellValue == null || cellValue == "") return "";
    var date = new Date(cellValue) 
    var year = date.getFullYear()
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() 
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() 
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() 
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  }