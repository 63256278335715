import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/reset.css'
import '../src/assets/css/base.css'
import { formatDate } from '@/utils/getDate'
// 引入像素自适应
import 'lib-flexible'

// 组织树状图
import Vue2OrgTree from 'vue2-org-tree'
Vue.use(Vue2OrgTree)

// 引入element组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)
ElementUI.Dialog.props.closeOnClickModal.default = false
ElementUI.MessageBox.setDefaults({
  closeOnClickModal: false
})

//富文本编辑器
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );


// 上下跑马灯
import scroll from "vue-seamless-scroll";
Vue.use(scroll)

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.formatDate = formatDate
Vue.config.productionTip = false

window.Vue=new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
